import React, { useState, useEffect } from 'react';
import SelectName from '../family-tree/select-name';
import SelectGautra from '../family-tree/select-gautra';
import { koot_falan } from '../../helpers/utils';
import { searchUser } from '../../actions/searchAction';
import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import SelectVillage from '../family-tree/select-village';

const SearchForm = ({
  search_params,
  sendResults,
  fields,
  name_value,
  gender_value,
  heading = '',
  updateMukhiya = false,
  independentUser = false,
  //isMukhiyaSearch = false,
  buttonText,
  onlyGautraDropDown,
}) => {
  console.log('search params', search_params);
  const [name, setName] = useState(search_params?.name || '');
  const [fatherName, setFatherName] = useState(search_params?.father_name || '');
  const [gautraObj, setGautraObj] = useState(
    { gautra: search_params?.gautra, subcaste: search_params?.subcaste } || {},
  );
  // const [gender, setGender] = useState(gender_value);
  const [loading, setLoading] = useState(false);
  const [village, setVillage] = useState(search_params?.village || '');
  // const [age, setAge] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  // const [isAlive, setAlive] = useState(true);
  const [errorMsgName, setErrorMsgName] = useState('');
  const [errorMsgGautra, setErrorMsgGautra] = useState('');
  const [searchResultErr, setSearchResultErr] = useState(false);
  const [genderValue, setGenderValue] = useState(gender_value || search_params?.gender || 'male');
  const [isOnlyGautra, setIsOnlyGautra] = useState(() => {
    if (!search_params) return false;
    return !search_params?.subcaste;
  });

  // useEffect(() => {
  //   setGender(gender_value);
  // }, [gender_value]);
  // console.log('Gender in Search Form ', gender_value);
  useEffect(() => {
    if (name) setErrorMsgName('');
    console.log('firstName:', name);
  }, [name]);

  useEffect(() => {
    if (gautraObj || name) setErrorMsgGautra('');
    console.log('gautra: ', gautraObj);
  }, [gautraObj, name]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setErrorMsgName('Please Enter Name');
      return;
    }
    if (!gautraObj && !name) {
      setErrorMsgGautra('Please Enter Gautra Name');
      return;
    }

    if (independentUser) {
      const data = {
        name: name,
        gautra: gautraObj?.gautra,
        subcaste: gautraObj?.subcaste,
        father_name: fatherName,
        village: village,
        gender: genderValue,
        mobile_no: mobileNo,
      };
      sendResults(data);
      return;
    }
    if (updateMukhiya) {
      const updatedMukhiyaData = {
        name: name.trim(),
        gautra: gautraObj?.gautra?.trim(),
        subcaste: gautraObj?.subcaste?.trim(),
        father_name: fatherName.trim(),
        village: village?.trim(),
        mobile: mobileNo?.trim(),
        gender: genderValue.trim(),
      };
      sendResults(updatedMukhiyaData);
      return;
    }
    let sentValue = {
      name: name,
      koot_falan: koot_falan(name),
      subcaste: gautraObj?.subcaste,
      gautra: gautraObj?.gautra,
      village_name: village?.trim(),
      gender: genderValue.trim(),
    };
    setLoading(true);
    try {
      const response = await searchUser(sentValue);
      console.log('response of search user', response);
      setLoading(false);
      const searchResults = response;
      if (searchResults.length === 0) {
        console.log('No results');
        setSearchResultErr(true);
        toast.error('No Results Found');
        return;
      }

      let searchParams = {};
      if (name) {
        searchParams.name = name.trim();
      }
      if (fatherName) {
        searchParams.father_name = fatherName.trim();
      }
      if (genderValue) {
        searchParams.gender = genderValue.trim();
      }
      if (gautraObj.gautra) {
        searchParams.gautra = gautraObj?.gautra?.trim();
        // searchParams.subcaste = gautraObj?.subcaste?.trim();
      }
      if (gautraObj.subcaste) {
        // searchParams.gautra = gautraObj?.gautra?.trim();
        searchParams.subcaste = gautraObj?.subcaste?.trim();
      }
      if (mobileNo) {
        searchParams.mobileNo = mobileNo.trim();
      }

      if (village) {
        searchParams.village = village.trim();
      }
      if (genderValue) {
        searchParams.gender = genderValue.trim();
      }

      console.log('Users Data ', response);
      sendResults(searchResults, searchParams);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
      toast.error(`Server Error: Unable to process request, ${error}`);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <h2>{heading}</h2>
          <label style={{ marginBottom: '10px', display: 'block' }}>
            Gender:
            <div style={{ marginTop: '5px' }}>
              <input
                type="radio"
                value="male"
                checked={genderValue === 'male'}
                onChange={() => setGenderValue('male')}
                style={{ marginRight: '5px' }}
                disabled={gender_value !== undefined}
              />
              <span style={{ marginRight: '15px' }}>Male</span>
              <input
                type="radio"
                value="female"
                checked={genderValue === 'female'}
                onChange={() => setGenderValue('female')}
                style={{ marginRight: '5px' }}
                disabled={gender_value !== undefined}
              />
              <span>Female</span>
            </div>
          </label>
          <br />
          {fields?.includes('name') && (
            <label>
              Name:
              <br />
              {/* {isAlive ? '' : 'स्वर्गीय '} */}
              <SelectName setName={setName} gender={genderValue} name={name} autoFocus={true} />
              {errorMsgName ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgName}</div> : ''}
            </label>
          )}
          <br />
          {fields?.includes('gautra') && (
            <>
              {/* {onlyGautraDropDow.n ? ( */}
              {/* <> */}
              <label>
                Gautra with Subcaste:
                <input
                  type="radio"
                  checked={!isOnlyGautra}
                  onChange={() => setIsOnlyGautra(false)}
                  style={{ marginLeft: '5px' }}
                />
              </label>
              <label style={{ marginLeft: '20px' }}>
                Gautra:
                <input
                  type="radio"
                  checked={isOnlyGautra}
                  onChange={() => setIsOnlyGautra(true)}
                  style={{ marginLeft: '5px' }}
                />
              </label>
              <SelectGautra
                isOnlyGautra={isOnlyGautra}
                setGautraObj={setGautraObj}
                gautraObj={gautraObj}
                // disabled={relation === 'parent' || relation === 'kid'}
              />
              {/* </> */}
              {/* ) : ( */}
              {/* <label>
                  Gautra:
                  <SelectGautra
                    setGautraObj={setGautraObj}
                    gautraObj={gautraObj}
                    // disabled={relation === 'parent' || relation === 'kid'}
                  />
                </label> */}
              {/* )} */}
              {errorMsgGautra ? <div style={{ color: 'red', fontWeight: '400' }}>{errorMsgGautra}</div> : ''}
            </>
          )}
          <br />
          {fields?.includes('father') && (
            <label>
              Father's Name (optional):
              <br />
              <SelectName setName={setFatherName} gender="male" name={fatherName} />
            </label>
          )}
          <br />
          {fields?.includes('village') && (
            <label>
              Village:
              <SelectVillage setVillage={setVillage} village={village} />
            </label>
          )}
          <br />
          {fields?.includes('mobileNo') && (
            <div>
              <label>
                Mobile No:
                <br />
                <input
                  type="text"
                  style={{ fontSize: '20px', padding: '3px' }}
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value.trim())}
                />
              </label>
            </div>
          )}
          {/* {labelsToShow.includes('Mobile No') && (
          <label>
            Mobile No:
            <input
              type="text"
              style={{ fontSize: '15px' }}
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value.trim())}
            />
          </label>
        )}
        <br />
        {labelsToShow.includes('Alive') && (
          <label>
            <input type="checkbox" onChange={() => setAlive(!isAlive)} checked={isAlive} />
            Alive
          </label>
        )} */}
          {/* <br /> */}
          <button style={{ marginTop: '8px' }} type="submit">
            {buttonText}
          </button>{' '}
          {searchResultErr ? 'No Results Found' : ''}
        </form>
      )}
    </div>
  );
};

export default SearchForm;
